<template>
  <section class="wrapper">
    <picture>
      <source
        media="(max-width: 480px)"
        srcset="@/assets/soon_open/mobile.webp"
      />
      <source
        media="(max-width: 768px)"
        srcset="@/assets/soon_open/tablet.webp"
      />
      <source
        media="(min-width: 769px)"
        srcset="
          @/assets/soon_open/hero.webp    1x,
          @/assets/soon_open/hero_2x.webp 2x
        "
      />
      <img src="img/hero.webp" />
    </picture>
  </section>
</template>

<style lang="scss" scoped>
/*Обнуление*/
* {
  padding: 0;
  margin: 0;
  border: 0;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
}
nav,
footer,
header,
aside {
  display: block;
}
html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
input,
button,
textarea {
  font-family: inherit;
}
input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

body {
  overflow: hidden;
  background: #000;
  height: 100%;
}

section {
  width: 100vw;
  height: 100vh;
}

@media (max-width: 480px) {
  section {
    margin-top: 58px;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
