<template>
  <div class="container">
    <div class="in-develop">
      <pre>В разработке, будет во второй итерации</pre>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.in-develop {
  width: 100%;
  height: calc(100vh - 220px);
  display: flex;
  flex-flow: nowrap column;
  justify-content: center;
  align-items: center;
}
</style>
